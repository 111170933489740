export const languageHashValues= {
  "GLOBAL": ".6ccfd2303bc5b0777437",
  "U4302_TRANSACTIONSDETAIL": ".b64f7652775337e82287",
  "U4400_PAYMENT": ".1439da9d75f826559776",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".2bd1ec3bfcf157a01391",
  "U2117_PADAGREEMENTINVITATION": ".5c5e60d13a25afbbd64c",
  "U2000_PAD": ".7ab074781894a91d38ea",
  "U4402_INVOICEPORTALPAYMENT": ".b0af8456b2cfdc13e916",
  "U2000_BANKACCOUNTCREATION": ".5355539c98b0e2d30839",
  "U2011_LOGIN": ".a5d0744ab916fd6cfbef",
  "U2012_LOGINHELP": ".21ca3881f10dbd4bd875",
  "U2014_SECURITY": ".18967e076caa6ca33724",
  "U2086_EFTPENDINGPAYMENTS": ".011c23d99daa4ddafed8",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".57cfb1b0e6f1bb696943",
  "U2110_ACCOUNTCREATION": ".08f86ce023e6fd186eb1",
  "U2121_INDIVIDUALPROFILE": ".5f14ec4a59417115f8ef",
  "U2131_ORGANIZATIONPROFILE": ".95a41825c511ca6f6f3c",
  "U2112_ERPBINDING": ".019d6fbda21d951b27bc",
  "U2113_SUPPLIERINVITATION": ".ba119eda0c8d8010cc77",
  "U2118_RBBINDING": ".692ca92821af9ec24a7e",
  "U2132_AFFILIATION": ".a5100889f8618cc3c45a",
  "U2133_BUSINESSLINKS": ".0266fe6a8fd6d78b214c",
  "U2181": ".1c3ed26ddf97c410e6af",
  "U2211_SUBSCRIPTIONOFFER": ".86054f927268d46674ec",
  "U2212_SUBSCRIPTIONFILTER": ".36377ee95ab124b98cd6",
  "U2213_SUBSCRIPTION": ".d801f47332a232a9329f",
  "U4111_CREDITCARDADDITION": ".1921eb072452629bd03b",
  "U4112_CREDITCARDDETAIL": ".3a889f84a2f7d47dd3c8",
  "U4121_BANKACCOUNTADDITION": ".46a56f5f1d2b1c0420ab",
  "U4122_BANKACCOUNTDETAIL": ".4419f92462bc4240a8ad",
  "U4303_ANONYMOUSTRANSACTION": ".4bbf248c771d6da5a7e8",
  "U4401_PAYMENTGATEWAY": ".7871bf10df0b496de1fb",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".62b28b9cbc0aa2aedda5",
  "U5000_RECEIVABLE": ".f3ed5c0fbf513b51c2a7",
  "U5101_STATEMENTOFACCOUNT": ".0ee0218a3b56ec298096",
  "U5102_INVOICE": ".0384efcd263192289cb6",
  "U5103_PADAGREEMENT": ".09272d13997466512e24",
  "U5121_SENTINVOICE": ".21ae40c7e9f3b8656b32",
  "U5124_PGRECEIVEDPAYMENT": ".edfe6b6d67b428525472",
  "U5120_INVOICES": ".8bb635c55186d4e65eb9",
  "U5123_PADAGREEMENTCONSOLE": ".d21e88106868c0fbc890",
  "U5132_PAYMENTRECONCILIATION": ".93f81380a8b336b257f0",
  "U5133_BANKRECONCILIATION": ".ebef3dc51a2bd6bd0f6f",
  "U6112_BUSINESSLINK": ".67dde935cfcc1f221a0d",
  "U6113_SUPPLIERRECORD": ".21e793e9c773fffb1c5f",
  "U6211_PAYLOT": ".b33bb4f6aa76259b46cb",
  "U6311_PURCHASE": ".40c94bd9f467f9e301b4",
  "U2115_AFFILIATIONINVITATION": ".a15dfee196544305fcd0",
  "U6100_PAYABLE": ".906544d9a9affb6b808e",
  "U6131_SENTPAYMENT": ".eb4ba265265ac827aaed",
  "U6191_SENTPAYMENTREPORT": ".7b809e5c3d3e2450d937",
  "U6221_SENTPAYMENT": ".644168bf9aa53d3b12bb",
  "U6422_INTERACSENTPAYMENT": ".799ee1228c53b0fc433b",
  "U6531_TRANSACTIONPAYMENT": ".a6e11fe52692c7678447",
  "U5131_RECEIVEDPAYMENT": ".966704b7d33226244893",
  "U5191_RECEIVEDPAYMENTREPORT": ".a14001e4519ac30c5850",
  "U2001_HOME": ".683ce37d4af75d7fdb62",
  "U2024_REPORTLOG": ".14c55329049d4a6f43ae",
  "U2111_INVOICEPORTALBINDING": ".f890bc370ac97c62f1e9",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".2868ad29909a1670026b",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".d48937ed5eb1ae78a84f",
  "U6511_TRANSACTIONPARTY": ".6689f3b61160978cb92a",
  "U6212_PAYROLLPARTY": ".e9c2add34b2602809c3a",
  "U6121_EFTLOT": ".60452841aba888cd93be",
  "U6412_INTERACFILE": ".739369bb173d8fe58b43",
  "U6122_EFTAPPROVAL": ".d9df6fe81a968ac0c5eb",
  "U6512_TRANSACTIONLOT": ".f31b7badc9d94e444a0f",
  "U6123_EFTAPPROVAL": ".d0e93dcd1c993107ec78",
  "U6123_EFTAPPROVALMOBILE": ".7c8d34a3f06ada360246",
  "U2012_LOGIN": ".495e304cd64b084dfbdc",
  "U2000_PAYMENT": ".6716a69e08ea54baada5",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".dbc6e54d1729e125dcac",
  "U2000_PAYMENTMODEUPDATE": ".77841fd3d72824b6086c",
  "U6114_TELUSBILLER": ".f66b74d8afa09e829051",
  "U4403_CREDITCARDTERMINAL": ".c64fdc94cd51b0fae697",
  "U4404_INTERACTERMINAL": ".97c8a4c11e70142a21d0",
  "U2000_SAMPLECHEQUESELECTION": ".103a8c2515c6b978ebed",
  "U2023_TUTORIALVIDEO": ".5f4958d269593106a996",
  "U2134_ORGANIZATIONBILLING": ".18caf66fb27b0675c776",
  "U6414_BANKACCOUNTWALLET": ".e1e931b2c07caeab960e",
  "U2022_INSTANTACCOUNTVERIFICATION": ".261882d5358eae477824",
  "U6532_PENDINGTRANSACTION": ".f2507cff333ad4a2c5dc",
  "U4301_TRANSACTIONSSUMMARY": ".e717a21d5cddfdc6ea05",
  "U5111_SENTINVOICESTRACKING": ".571e20a81ce0e19d453d",
  "U2026_NETPROMOTERSCORE": ".c8a7a4bcc8b4234f7076",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".f4abb8f80094b113320c",
  "U2000_INTRO": ".fc18e8226e39e9e1b4ed",
  "U2000_ELECTRONICSIGNATURE": ".3bf10f0ec0b033b36116",
  "U2000_VERIFYEMAIL": ".0d6995be59ce3cb4bbd0",
  "U2200_SUBSCRIPTION": ".a8885414f7c3ebb04c4b",
  "U2000_VERIFYIDENTITY": ".b7a42943e416d1132e28",
  "U2000_ATTACHMENTVIEWER": ".6947055b3510dfe7bad2",
  "U2212_PLANLIST": ".10e4e1f9e332240a63f3",
  "U3002_TAXBILLSEARCHRESULT": ".22bd076bd2d9942c794b"
}